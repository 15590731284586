<template>
  <v-container>
    <v-card class="d-flex flex-row mb-6" flat tile>
      <v-icon
        left
        @click="$router.go(-1)"
        class="pr-2 pt-2 pb-2"
        size="30"
        color="black"
      >
        mdi-arrow-left
      </v-icon>
      <v-card class="pa-2" flat>
        <h3>{{ $t("message.title-order-detail") }}</h3>
      </v-card>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="blue accent-4"
      ></v-progress-linear>
    </v-card>

    <div v-if="loading == false">
      <OrderDetailInfo
        v-if="orderDetail.orders"
        :orderDetail="orderDetail.orders"
      />

      <v-pagination
        v-model="page"
        :length="lengthPage"
        :total-visible="7"
        @input="changePage"
      ></v-pagination>

      <div v-if="orderDetail.orders">
        <v-data-table
          :headers="headers"
          :items="orderDetail.orders.detail.data"
          disable-sort
          :hide-default-footer="true"
          class="elavation-1"
          :items-per-page="orderDetail.orders.detail.per_page"
          :server-items-length="orderDetail.orders.detail.total"
          :options.sync="option"
          :footer-props="{
            'items-per-page-options': [
              orderDetail.orders.detail.per_page != null ? orderDetail.orders.detail.per_page : 10,
            ],
          }"
          :page="orderDetail.orders.detail.currentPage"
        >
          <!-- <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            :items-per-page-text="$t('message.rows-per-page')"
            class="elavation-0 v-data-footer-top"
          />
        </template> -->
        </v-data-table>
      </div>

      <div class=" mt-8" v-if="orderDetail.orders !== undefined">
        <div
          class="d-flex flex-row justify-end"
          v-if="orderDetail.orders.subtotal > 0"
        >
          <div
            class="d-flex flex-column col-10 col-md-10 align-end"
            style="font-size: 14px; line-height:  1px"
          >
            <span>{{ $t("message.label-subtotal") }} :</span>
          </div>
          <div
            class="d-flex flex-column col-md-2"
            style="font-size: 14px; line-height:  1px"
          >
            ¥{{ moneyFormat(orderDetail.orders.subtotal) }}
          </div>
        </div>
        <div
          class="d-flex flex-row justify-end"
          v-if="orderDetail.orders.subtotal_exclude_taxable_value_8 > 0"
        >
          <div
            class="d-flex flex-column col-10 col-md-10 align-end"
            style="font-size: 14px; line-height:  1px"
          >
            <span
              >{{
                $t("message.label-subtotal_exclude_taxable_value_8")
              }}
              :</span
            >
          </div>
          <div
            class="d-flex flex-column col-md-2"
            style="font-size: 14px; line-height:  1px"
          >
            ¥{{
              moneyFormat(orderDetail.orders.subtotal_exclude_taxable_value_8)
            }}
          </div>
        </div>
        <div
          class="d-flex flex-row justify-end"
          v-if="orderDetail.orders.subtotal_consumption_tax_8 > 0"
        >
          <div
            class="d-flex flex-column col-10 col-md-10 align-end"
            style="font-size: 14px; line-height:  1px"
          >
            <span>{{ $t("message.label-subtotal_consumption_tax_8") }} :</span>
          </div>
          <div
            class="d-flex flex-column col-md-2"
            style="font-size: 14px; line-height:  1px"
          >
            ¥{{ moneyFormat(orderDetail.orders.subtotal_consumption_tax_8) }}
          </div>
        </div>
        <div
          class="d-flex flex-row justify-end"
          v-if="orderDetail.orders.subtotal_exclude_taxable_value_10 > 0"
        >
          <div
            class="d-flex flex-column col-10 col-md-10 align-end"
            style="font-size: 14px; line-height:  1px"
          >
            <span
              >{{
                $t("message.label-subtotal_exclude_taxable_value_10")
              }}
              :</span
            >
          </div>
          <div
            class="d-flex flex-column col-md-2"
            style="font-size: 14px; line-height:  1px"
          >
            ¥{{
              moneyFormat(orderDetail.orders.subtotal_exclude_taxable_value_10)
            }}
          </div>
        </div>
        <div
          class="d-flex flex-row justify-end"
          v-if="orderDetail.orders.subtotal_consumption_tax_10 > 0"
        >
          <div
            class="d-flex flex-column col-10 col-md-10 align-end"
            style="font-size: 14px; line-height:  1px"
          >
            <span>{{ $t("message.label-subtotal_consumption_tax_10") }} :</span>
          </div>
          <div
            class="d-flex flex-column col-md-2"
            style="font-size: 14px; line-height:  1px"
          >
            ¥{{ moneyFormat(orderDetail.orders.subtotal_consumption_tax_10) }}
          </div>
        </div>
        <div
          class="d-flex flex-row justify-end"
          v-if="orderDetail.orders.subtotal_include_taxable_value_8 > 0"
        >
          <div
            class="d-flex flex-column col-10 col-md-10 align-end"
            style="font-size: 14px; line-height:  1px"
          >
            <span
              >{{
                $t("message.label-subtotal_include_taxable_value_8")
              }}
              :</span
            >
          </div>
          <div
            class="d-flex flex-column col-md-2"
            style="font-size: 14px; line-height:  1px"
          >
            ¥{{
              moneyFormat(orderDetail.orders.subtotal_include_taxable_value_8)
            }}
          </div>
        </div>
        <div
          class="d-flex flex-row justify-end"
          v-if="orderDetail.orders.subtotal_consumption_taxable_8 > 0"
        >
          <div
            class="d-flex flex-column col-10 col-md-10 align-end"
            style="font-size: 14px; line-height:  1px"
          >
            <span
              >{{ $t("message.label-subtotal_consumption_taxable_8") }} :</span
            >
          </div>
          <div
            class="d-flex flex-column col-md-2"
            style="font-size: 14px; line-height:  1px"
          >
            ¥{{
              moneyFormat(orderDetail.orders.subtotal_consumption_taxable_8)
            }}
          </div>
        </div>
        <div
          class="d-flex flex-row justify-end"
          v-if="orderDetail.orders.subtotal_include_taxable_value_10 > 0"
        >
          <div
            class="d-flex flex-column col-10 col-md-10 align-end"
            style="font-size: 14px; line-height:  1px"
          >
            <span
              >{{
                $t("message.label-subtotal_include_taxable_value_10")
              }}
              :</span
            >
          </div>
          <div
            class="d-flex flex-column col-md-2"
            style="font-size: 14px; line-height:  1px"
          >
            ¥{{
              moneyFormat(orderDetail.orders.subtotal_include_taxable_value_10)
            }}
          </div>
        </div>
        <div
          class="d-flex flex-row justify-end"
          v-if="orderDetail.orders.subtotal_consumption_taxable_10 > 0"
        >
          <div
            class="d-flex flex-column col-10 col-md-10 align-end"
            style="font-size: 14px; line-height:  1px"
          >
            <span
              >{{ $t("message.label-subtotal_consumption_taxable_10") }} :</span
            >
          </div>
          <div
            class="d-flex flex-column col-md-2"
            style="font-size: 14px; line-height:  1px"
          >
            ¥{{
              moneyFormat(orderDetail.orders.subtotal_consumption_taxable_10)
            }}
          </div>
        </div>
        <div
          class="d-flex flex-row justify-end"
          v-if="orderDetail.orders.subtotal_non_taxable_value > 0"
        >
          <div
            class="d-flex flex-column col-10 col-md-10 align-end"
            style="font-size: 14px; line-height:  1px"
          >
            <span>{{ $t("message.label-subtotal_non_taxable_value") }} :</span>
          </div>
          <div
            class="d-flex flex-column col-md-2"
            style="font-size: 14px; line-height:  1px"
          >
            ¥{{ moneyFormat(orderDetail.orders.subtotal_non_taxable_value) }}
          </div>
        </div>
        <div class="d-flex flex-row justify-end">
          <div
            class="d-flex flex-column col-10 col-md-10 align-end"
            style="font-size: 14px; line-height:  1px"
          >
            <span>{{ $t("message.label-delivery_fee_display") }} :</span>
          </div>
          <div
            class="d-flex flex-column col-md-2"
            style="font-size: 14px; line-height:  1px"
          >
            ¥{{ moneyFormat(orderDetail.orders.delivery_fee_display) }}
          </div>
        </div>
        <div class="d-flex flex-row justify-end">
          <div
            class="d-flex flex-column col-10 col-md-10 align-end"
            style="font-size: 14px; line-height:  1px"
          >
            <span
              >{{
                $t("message.label-total_amount_w_delivery_fee_per_store")
              }}
              :</span
            >
          </div>
          <div
            class="d-flex flex-column col-md-2"
            style="font-size: 14px; line-height:  1px"
          >
            ¥{{
              moneyFormat(
                orderDetail.orders.total_amount_w_delivery_fee_per_store
              )
            }}
          </div>
        </div>
      </div>

      <div class="my-8">
        <div class="d-flex flex-column my-10 w-100">
          <div style="margin-left:-9px;font-weight:bold">【備考】</div>
          <div
            style="text-align: justify;font-weight:500"
            v-if="orderDetail.orders.order_shipping_type == 1"
          >
            置き配を希望する
          </div>
          <div style="text-align:justify; white-space: pre-line;">
            {{ orderDetail.orders.notes }}
          </div>
        </div>
      </div>

      <div>
        <OrderDetailDeliveryInfo
          v-if="orderDetail.delivered_from"
          :orderDetailAdressFrom="orderDetail.delivered_from"
          :orderDetailAdressTo="orderDetail.delivered_to"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import { mdiArrowLeft } from "@mdi/js";

import OrderDetailInfo from "@/components/OrderDetailInfo";
import OrderDetailDeliveryInfo from "@/components/OrderDetailDeliveryInfo";
import moneyFormat from "../helpers/moneyFormat";
export default {
  name: "OrderDetail",
  components: {
    OrderDetailInfo,
    OrderDetailDeliveryInfo,
  },
  data() {
    return {
      iconArrowLeft: mdiArrowLeft,
      page: 1,
      option: {},
      headers_title: [
        { text: "Item Name", align: "start", value: "item_name" },
        { text: "Weight", value: "weight" },
        { text: "Qty", value: "qty" },
        { text: "Price", value: "price" },
        { text: "Total Price", value: "total_price_items" },
        { text: "Tax", value: "tax" },
      ],
      loading: true,
    };
  },
  // watch: {
  //   loading(val) {
  //     if (!val) return;

  //     setTimeout(() => (this.loading = false), 3000);
  //   },
  // },
  computed: {
    lengthPage() {
      return this.$store.state.order_module.orderDetail.length_page;
    },
    orderDetail() {
      return this.$store.state.order_module.resultOrderDetail;
    },
    headers() {
      return [
        {
          text: this.$t("message.label-item"),
          align: "start",
          value: "item_name",
        },
        { text: this.$t("message.label-weight"), value: "weight" },
        { text: this.$t("message.label-quantity"), value: "qty" },
        {
          text: this.$t("message.label-price"),
          value: "price_display_peritem",
        },
        {
          text: this.$t("message.label-total-price"),
          value: "price_display",
        },
        { text: this.$t("message.label-tax"), value: "tax_type_display" },
      ];
    },
  },
  created() {
    this.dispatchOrderDetail();
  },
  methods: {
    moneyFormat(money) {
      return moneyFormat(money);
    },
    async changePage(event) {
      let id = this.$router.history.current.params.id;
      return await this.$store.dispatch("order_module/fetchOrderDetail", {
        page: event,
        id: id
      });
    },
    async dispatchOrderDetail() {
      let id = this.$router.history.current.params.id;
      let pageInit = parseInt(this.$store.state.order_module.orderDetail.page);
      this.page = parseInt(this.$store.state.order_module.orderDetail.page);

      this.loading = true;
      await this.$store.dispatch("order_module/fetchOrderDetail", {
        id: id,
        page: pageInit,
      });
      this.loading = false;
    },
  },
};
</script>

<style>
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
</style>
