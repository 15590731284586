<template>
  <v-card class="d-flex flex-wrap mb-5" flat tile >
    <v-row style="width: 100%">
      <v-col md="3" xs="1" cols="6">
        <span style="font-weight: bold">{{ $t("message.label-customer") }} :</span>
      </v-col>
      <v-col cols="6" md="9" xs="1">
        <div>
          <span style="font-weight: 500"><a href="" style="" @click="redirectToCustomerDetail(orderDetailAdressTo.customer_id)">{{ orderDetailAdressTo.customer_name }} ({{ orderDetailAdressTo.customer_name_furigana }})</a></span>
        </div>
        <div>
          {{ orderDetailAdressTo.customer_phone }}
        </div>
        <div>
          {{ orderDetailAdressTo.customer_email }}
        </div>
      </v-col>
    </v-row>
    <v-row style="width:100%">
      
      <v-col md="3" xs="1" cols="6">
        <span style="font-weight: bold">{{ $t("message.label-delivery-from") }}: </span>
      </v-col>
      <v-col md="9" xs="1" cols="6">
        <div><span style="font-weight: 500">{{orderDetailAdressFrom.store_name}}</span></div>
        <div>
          〒{{ formatPostalcode(orderDetailAdressFrom.postal_code) }} {{orderDetailAdressFrom.prefecture}} {{orderDetailAdressFrom.city}} {{orderDetailAdressFrom.district}}
        </div>
        <div>
          {{orderDetailAdressFrom.store_address}}
        </div>
      </v-col>
    </v-row>
    <v-row style="width:100%">
      <v-col md="3" xs="1" cols="6">
        <span style="font-weight: bold">{{ $t("message.label-delivery-to") }}: </span>
      </v-col>
      <v-col md="9" xs="1" cols="6">
        <div><span  style="font-weight: 500">{{orderDetailAdressTo.address_name}}</span><span>（{{orderDetailAdressTo.customer_name}}）</span></div>
        <div>
          {{ orderDetailAdressTo.address_raw }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "OrderDetailDeliveryInfo",
  data() {
    return {
      detailOrder: {}
    }
  },
  props: {
    orderDetailAdressFrom: Object,
    orderDetailAdressTo: Object,
  },
  methods: {
    formatPostalcode(postalcode) {
      if(postalcode != null && postalcode != undefined) {
        let convertStr = postalcode.toString()
        let newPostcode = convertStr.slice(0, 3)+'-'+convertStr.slice(3)
        return newPostcode
      }
    },
    redirectToCustomerDetail(customer) {
      this.$router.push(`/customer/${customer}`);
    }
  }
};
</script>

<style></style>
