<template>
  <v-card class="d-flex flex-wrap mb-5" flat tile>
    <v-row>
      <v-col md="2" xs="1" cols="6">
        <span style="font-weight: bold">{{ $t("message.label-order-number") }}: </span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span>{{orderDetail.order_no}}</span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span style="font-weight: bold">{{ $t("message.label-invoice") }}: </span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span>{{orderDetail.invoice_no}}</span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span style="font-weight: bold">{{ $t("message.label-amount") }}: </span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span>¥{{ moneyFormat(orderDetail.total_amount_w_delivery_fee_per_store)}}</span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span style="font-weight: bold">{{ $t("message.label-order-datetime") }}: </span>
      </v-col>
      <v-col cols="2">
        <span>{{orderDetail.created_at}}</span>
      </v-col>
      <v-col md="2" xs="1" cols="6">
        <span style="font-weight: bold">{{ $t("message.label-status") }}: </span>
      </v-col>
      <v-col md="auto" xs="1" cols="6">
        <span>{{orderDetail.status}}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moneyFormat from '../helpers/moneyFormat'
export default {
  name: "OrderDetailInfo",
  data() {
    return {
      detailOrder: {}
    }
  },
  props: {
    orderDetail: Object
  },
  methods: {
    moneyFormat(money) {
      return moneyFormat(money)
    }
  }
};
</script>

<style></style>
